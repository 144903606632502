import React from "react";
import SectionLayout from "../../layouts/SectionLayout";
import { BlogSlices } from "../../components/SliceZone/BlogSlices";


const BlogSliceSSection = ({ slices }) => {

    return (

        <SectionLayout>
            <div className="blogContainer">
                <div className="col-lg-8 contentContainer"> 
                    <BlogSlices slices={slices}/>
                </div>
            </div>
        </SectionLayout>
    )
}

export default BlogSliceSSection
