import React from "react";
import { Image } from "../components/Image/Image";

const BlogImage = ({ slice }) => {

    const image = slice.primary.image

    return (
        <>
            <Image 
                src={image}
                loading={"eager"}
                fadeIn={false} 
            />
        </>
    )
}


export default BlogImage