import React from "react"
import BlogContent from "../../slices/BlogContent"
import BlogImage from "../../slices/BlogImage"
import BlogVideo from "../../slices/BlogVideo"

export const BlogSlices = ({ slices }) => {

    const sliceComponents = {
        blog_richtext: BlogContent,
        blog_image: BlogImage,
        blog_video: BlogVideo,
    }

    return slices.map((slice, index) => {
        const SliceComponent = sliceComponents[slice.slice_type]
        if (SliceComponent) {
            return <SliceComponent slice={slice} key={`slice-${index}`} index={index} />
        }
        return null
    })
}