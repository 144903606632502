import React from "react";
import SideNav from "../../components/SideNavComponent/SideNavComponent";
import { SignalSlices } from "../../components/SliceZone/SignalSlices";

const SignalsContent = ({ signalsData }) => {

    return (
        <div className="d-flex">
            <SideNav data={signalsData}/>
            <div className="slices">
                <SignalSlices slices={signalsData}/>
            </div>
        </div>
    )
}

export default SignalsContent