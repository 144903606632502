import React from "react";
import { SignalSlices } from "../../components/SliceZone/SignalSlices";
import SignalsContent from "./SingnalsContent";

const SignalsSection = ({ profitSlice, signalsData }) => {

    return (

        <>
            {signalsData.length !== 0 &&
                <div className="layout-container">
                    <SignalSlices slices={profitSlice}/>
                    <SignalsContent signalsData={signalsData} />
                </div>
            }
        </>
    )
}

export default SignalsSection
