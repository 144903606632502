import React from "react";
import { Link } from "gatsby";

const NavItem = ({ item }) => {

    return (
        <div className="sideNavbar__container">
            <h6>{item.primary.header}</h6>
            {item.items.map(filter => (
                <Link to={`#${item.primary.header} ${filter.filter_title}`}>
                    <p>{filter.filter_title}</p>
                </Link>
            ))}
        </div>
    )
}

export default NavItem