import React from "react";
import { graphql } from "gatsby";
import Seo from "../components/Seo/Seo";
import BlogPostLayout from "../layouts/BlogPostLaout";
import BlogContentSection from "../sections/BlogContentSection/BlogContentSection";

const BlogPost = ({ data }) => {

    const pageContent = data.prismicBlogpost
    const page = pageContent.data || {}

    const metaTitle = page.meta_title
    const metaDescription = page.meta_description
    const metaKeywords = page.meta_keywords

    const kicker = page.kicker
    const title = page.title
    const image = page.hero_image

    const bannerTitle = page.blog_banner_title
    const btn1title = page.banner_first_btn_title
    const btn2title =  page.banner_second_btn_title

    const slices = page.body

    const contentData = {
      bannerTitle,
      btn1title,
      btn2title,
      slices,
      kicker,
      title,
      image
    }

    return (
        <BlogPostLayout>
          <Seo
            title={metaTitle}
            description={metaDescription}
            keywords={metaKeywords}
          />
          <BlogContentSection 
            data={contentData}
          />
        </BlogPostLayout>
    )
}

export const query = graphql`
query Blogpost($id: String) {
  prismicBlogpost(id: {eq: $id}) {
    id
    lang
    uid
    url
    data {
      title
      short_title
      promoted
      meta_title
      meta_keywords
      meta_description
      kicker
      flag_page
      category
      hero_image {
        url
        alt
        gatsbyImageData
      }
      body {
        ... on PrismicBlogpostDataBodyProfitSlice {
          id
          slice_type
          slice_label
          primary {
            profits_img {
              alt
              url
              gatsbyImageData
            }
          }
        }
        ... on PrismicBlogpostDataBodyExchangeSignals {
          id
          slice_type
          slice_label
          primary {
            header
          }
          items {
            filter_title
            description
            img {
              url
              gatsbyImageData
              alt
            }
          }
        }
        ... on PrismicBlogpostDataBodyBlogRichtext {
          id
          slice_type
          slice_label
          primary {
            blog_content {
              raw
            }
          }
        }
        ... on PrismicBlogpostDataBodyBlogTable {
          id
          slice_label
          slice_type
          primary {
            table {
              html
              raw
              text
            }
          }
        }
        ... on PrismicBlogpostDataBodyBlogVideo {
          id
          slice_label
          slice_type
          primary {
            id
            movietitle
          }
        }
        ... on PrismicBlogpostDataBodyBlogImage {
          id
          slice_type
          slice_label
          primary {
            image {
              url
              gatsbyImageData
              alt
            }
          }
        }
      }
      blog_banner_title
      banner_second_btn_title
      banner_first_btn_title
    }
  }
}

`;

export default BlogPost