import React from "react";
import { PlayButton } from "../../utils/imgLoader";


const VideoComponent = ({ videoID, videoTitle, ...props }) => {

    return (
      <div className="video ">
        <iframe
            id="videoframe"
            title={videoTitle}
            srcdoc={`<style>*{padding:0;margin:0;overflow:hidden}html,body{background:#000;height:100%}img{position:absolute;width:100%;top:0;bottom:0;margin:auto}</style>
            <a href=https://www.youtube-nocookie.com/embed/${videoID}?autoplay=1&modestbranding=1&iv_load_policy=3&theme=light&playsinline=1>
            <img src=https://i.ytimg.com/vi_webp/${videoID}/sddefault.webp>
            <img id='playbutton' src=${PlayButton} style='width: 88px; position: absolute; left: 42%;'></a>`}
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            frameBorder="0"
            webkitallowfullscreen="true"
            mozallowfullscreen="true"
            allowFullScreen
            width="100%"
            height="100%"
            loading="lazy"
            {...props}
        />
      </div>
    )
}


export default VideoComponent