import React from "react";
import SectionLayout from "../../layouts/SectionLayout";

const BlogBanner = ({ title,  btn1title, btn2title }) => {

    return (

        <SectionLayout>
                <div className="blogContainer">
                  <div className="col-lg-10">
                    <div className="cta-banner">
                      <h2 className="cta-banner__title">
                        {title}
                      </h2>
                      <div className="btn-container">
                            <a href={'https://app.cryptobasescanner.com/sign_in'} className="btn blogButton btn-primary">
                                {btn1title}
                            </a>
                            <a className="btn blogButton btn-outline-secondary" href={'https://cryptobasescanner.com/#pricing'}>{btn2title}</a>
                        </div>
                    </div>
                  </div>
                </div>
        </SectionLayout>
    )
}

export default BlogBanner