import React from "react";
import SignalItem from "../components/SignalItem/SignalItem"

const SignalSlice = ({ slice }) => {

    const title = slice.primary.header
    const signals = slice.items

    const Signals = () => {
        
        return (
            signals.map(item => (
                <SignalItem item={item} title={title}/>
            ))
        )
    }

    return (

        <div className="signalsSlice">
            <h2>{title}</h2>
            <Signals />
        </div>
    )
}

export default SignalSlice