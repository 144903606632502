import React from "react";
import BlogHero from "../../components/BlogHero/BlogHero";
import BlogSliceSSection from "./BlogSlicesSection";

const BlogSection = ({ title, kicker, image, contentSlices }) => {


    return (
        <>
            <BlogHero title={title} kicker={kicker} image={image}/>
            <BlogSliceSSection slices={contentSlices} />
        </>
    )
}


export default BlogSection